
import {Component, Vue, Watch} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import BuroForm from "@/components/forms/BuroForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {BuroEntity} from "@/entity/BuroEntity";
import AdliyePicker from "@/components/pickers/AdliyePicker.vue";
import SayfaBilgiInfo from "@/components/SayfaBilgiInfo.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";
import SayfaAciklama from "@/components/SayfaAciklama.vue";

@Component({
  components: {SayfaAciklama, SayfaBilgiInfo, AdliyePicker, FormDialog, BuroForm, DeleteDialog}
})
export default class BuroListeView extends Vue {
  search: string = "";
  iconName = 'mdi-trash-can-outline'
  programPaths = ProgramPaths;
  allCheckbox=false
  buroCheckbox: boolean[] = [];
  items: Array<BuroEntity> = [];
  checkbox = false
  headers = [
/*
    {text: '', align: 'start', sortable: false, value: 'checkbox' },
*/
    {text: "Ad", align: 'start',  sortable: true, value: "isim"},
    {text: "İl", align: 'center',  value: "adres.sehir.isim"},
    {text: "Vergi Dairesi", align: 'center', value: "vergi_dairesi"},
    {text: "Vergi No", align: 'center', value: "vergi_no"},
    {text: "İşlemler", align: 'end', value: "actions", sortable: false, width: '100'}
  ];

  oncekiSayfa(){
    this.$router.push(this.programPaths.ofispro)
  }

 /*selectedDelete(){
   const selectedItems = this.items.filter((item, index) => {
     return this.buroCheckbox[index];
   });

   const selectedIds = selectedItems.map(item => item.id);

   this.$http.delete('/api/v1/buro/' ,{
     params: {
       ids: [selectedIds]
     }
   } ).then(this.load)
   console.log('idler--->',selectedIds)
 }
  @Watch('allCheckbox')
  AllSelectCheckbox(){
    if (this.allCheckbox){
      this.buroCheckbox = this.items.map(item => true);
    }else{
      this.buroCheckbox = this.items.map(item => false);
    }
  }*/
  mounted() {
    this.load();

  }

  load() {
    this.$http.get('/api/v1/buro').then(response => {this.items = response.data});
  }

  add() {
    this.$router.push('buro/ekle');
  }

  detay(item: BuroEntity) {
    this.$router.push('buro/' + item.id);
  }
}
